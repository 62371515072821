import { FunctionComponent, useMemo } from 'react';
import { Badge } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('SecurityRiskTag').create(() => ({
  container: {
    padding: 'var(--space-1)',
    fontSize: '12px',
    fontWeight: 900,
    lineHeight: '12px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
  }
}));

type SecurityRiskTagProps = {
  value: string;
};

export const SecurityRiskTag: FunctionComponent<SecurityRiskTagProps> = ({ value }) => {
  const { classes } = useStyles();

  const color = useMemo(() => {
    if (value.toLocaleLowerCase() === 'high') {
      return 'red';
    } else if (value.toLocaleLowerCase() === 'medium') {
      return 'amber';
    } else if (value.toLocaleLowerCase() === 'low') {
      return 'green';
    }
    return undefined;
  }, [value]);

  return (
    <Badge size='3' color={color} className={classes.container}>
      {value} Risk
    </Badge>
  );
};
