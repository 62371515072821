import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { DASHBOARD_CONTROLS_API_ROOT } from 'app/constants/routes';
import { DashboardControl } from 'app/types';

export const useFetchDashboardControls = (enabled = false) => {
  return useQuery({
    queryKey: ['dashboard-controls'],
    queryFn: async () => {
      const response = await axiosInstance.get<DashboardControl[]>(
        DASHBOARD_CONTROLS_API_ROOT.ROOT,
        // TODO - customerId should not be hard-coded
        { params: { customerId: 1 } }
      );
      return response.data;
    },
    enabled
  });
};
