import { Flex } from '@radix-ui/themes';

import { DashboardStatusIcon } from 'app/components/Dashboard';
import {RISK_VALUE } from 'app/constants/dashboard';
import { DashboardControl } from 'app/types/dasboard';

import { groupedByRisk } from '../dashboard.utils';
import { RiskLabel } from '../helpers/RiskLabel';

  export const groupedByRiskMap = (statuses: DashboardControl[], riskName: 'businessRisk' | 'securityRisk') => {
    return (
      <Flex gap='4' direction={'column'}>
        {Object.keys(groupedByRisk(statuses, riskName)).map((riskValue, index) => (
          <Flex key={index} direction={'column'} gap='1'>
            {RiskLabel(riskValue as RISK_VALUE)}
            <Flex gap='2px' wrap={'wrap'}>
              {groupedByRisk(statuses, riskName)[riskValue]!.map((status: DashboardControl) => (
                <div key={status.name}>
                  <DashboardStatusIcon status={status.status} />
                </div>
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  };
