import { AUTH_INSTANCE } from './constants';

export const getAccessToken = async () => {
  // if mocking, then return empty string
  if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_AUTH === 'true') return '';

  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = AUTH_INSTANCE.getActiveAccount();
  const accounts = AUTH_INSTANCE.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }

  const val = await AUTH_INSTANCE.acquireTokenSilent({
    // scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/.default`],
    scopes: [],
    // prompt: 'consent', // ignored
    account: activeAccount || accounts[0]
  }).catch(e => {
    throw new Error(e || 'Unable to obtain access token');
  });

  return val.idToken;
};
