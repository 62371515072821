import { createContext } from 'react';
import { MsalProvider, MsalProviderProps } from '@azure/msal-react';

const MockContext = createContext<MsalProviderProps>({} as MsalProviderProps);
const MockProvider: React.FC<MsalProviderProps> = ({ children, instance }) => (
  <MockContext.Provider value={{ instance }}>{children}</MockContext.Provider>
);

export const AuthProvider =
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_AUTH === 'true'
    ? MockProvider
    : MsalProvider;
