import { useMutation, useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { CONTROLS_API_URLS } from 'app/constants/routes';
import { CustomerControl } from 'app/types';

export const useFetchCustomerControls = (enabled = false) => {
  return useQuery({
    queryKey: ['useFetchCustomerControls'],
    queryFn: () =>
      axiosInstance
        .get<CustomerControl[]>(CONTROLS_API_URLS.ROOT, {
          // TODO - customerId should not be hard-coded
          params: { customerId: 1 }
        })
        .then(response => response.data),
    enabled
  });
};

export const useFetchCustomerControl = (enabled = false, controlId: number) => {
  return useQuery({
    queryKey: ['useFetchCustomerControl', controlId],
    queryFn: async () =>
      await axiosInstance
        .get<CustomerControl>(
          CONTROLS_API_URLS.DETAIL.replace(':controlId', `${controlId}`),
          // TODO - customerId should not be hard-coded
          { params: { customerId: 1 } }
        )
        .then(response => response.data),
    enabled
  });
};

export interface InitialFindingsMutationOptions {
  externalControlId: number;
  initialFindings: string;
}
export const useInitialFindingsMutation = () => {
  const customerId = 1;
  return useMutation<CustomerControl, unknown, InitialFindingsMutationOptions>({
    mutationKey: ['useInitialFindingsMutation'],
    mutationFn: async ({ externalControlId, initialFindings }: InitialFindingsMutationOptions) =>
      await axiosInstance
        .put<CustomerControl>(
          CONTROLS_API_URLS.INITIAL_FINDINGS.replace(':controlId', `${externalControlId}`),
          {
            customerId,
            initialFindings
          }
        )
        .then(response => response.data)
  });
};
