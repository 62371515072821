import { Spinner } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('AppLoading').create(() => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: '32px',
    width: '100%'
  }
}));

export const AppLoading = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container} data-testid='app-loading'>
      <Spinner size='3' />
    </div>
  );
};
