import { FunctionComponent } from 'react';
import { Box, Button, Flex, Text } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { ControlStatusIcon } from 'app/components/Controls';
import { CONTROL_STATUS, CONTROL_STATUS_LABELS } from 'app/constants/controls';

const useStyles = tss.withName('StatusSection').create(() => ({
  container: {
    borderColor: 'var(--slate-200)',
    padding: '16px 0',
    borderBottom: '1px solid var(--slate-200)'
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700
  },
  statuses: {
    backgroundColor: 'var(--bg-default)',
    padding: '5px',
    borderRadius: '6px'
  },
  button: {
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      backgroundColor: 'var(--white)'
    }
  },
  buttonActive: {
    backgroundColor: 'var(--white)'
  }
}));

type StatusSectionProps = {
  controlStatus?: CONTROL_STATUS;
};

export const StatusSection: FunctionComponent<StatusSectionProps> = ({
  controlStatus = CONTROL_STATUS.NOT_STARTED
}) => {
  const { classes } = useStyles();

  const allControlStatuses = Object.values(CONTROL_STATUS);

  return (
    <Box className={classes.container} data-testid='side-panel-section'>
      <Flex gap='2' direction='column'>
        <Flex gap='2' justify={'between'} flexGrow={'1'} align={'center'}>
          <Text as='p' className={classes.heading}>
            Status
          </Text>
          {CONTROL_STATUS_LABELS[controlStatus]}
        </Flex>

        <Box className={classes.statuses}>
          <Flex gap='2' align='center' justify={'between'}>
            {allControlStatuses.map(status => (
              <Button
                className={clsx(classes.button, status === controlStatus && classes.buttonActive)}
                key={status}
              >
                <ControlStatusIcon status={status as CONTROL_STATUS} size={16} />
              </Button>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
