import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { Theme } from '@radix-ui/themes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppLayout } from './components/AppLayout';
import { AppLoading } from './components/AppLoading';
import { LoggedInUserProvider } from './hooks/useLoggedInUser';
import { AuthWrapper } from './auth';

export const App: FunctionComponent = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return (
    <Theme>
      <AuthWrapper
        interactionType={InteractionType.Redirect}
        // errorComponent={ErrorComponent}
        loadingComponent={AppLoading}
      >
        <QueryClientProvider client={queryClient}>
          <LoggedInUserProvider>
            <BrowserRouter>
              <AppLayout />
            </BrowserRouter>
          </LoggedInUserProvider>
        </QueryClientProvider>
      </AuthWrapper>
    </Theme>
  );
};

export default App;
