import { FunctionComponent } from 'react';
import { Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PageWrapper } from '../PageWrapper/PageWrapper';

const useStyles = tss.withName('UnauthorisedPage').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '48px',
    width: '100%'
  }
}));

export const UnauthorisedPage: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <PageWrapper>
      <Flex title='Unauthorised' data-testid='unauthorised' className={classes.container}>
        <Heading>Unauthorised Access</Heading>
        <Text as='p'>You do not have permission to view this page.</Text>
      </Flex>
    </PageWrapper>
  );
};
