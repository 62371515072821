import { FunctionComponent } from 'react';
import { Card, Flex, Separator } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { DashboardControl } from 'app/types/dasboard';

import { ProgressReportLegend } from './ProgressReportLegend';
import { ProgressReportTabs } from './ProgressReportTabs';

const useStyles = tss.withName('ProgressReport').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--bg-cards, #FFF)',
    padding: '24px',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)',
    width: '100%',
    color: 'var(--font-primary-color)'
  },
  separator: {
    flexBasis: '1px',
    background: 'var(--slate-200)',
    height: 'auto'
  }
}));

type ProgressReportProps = {
  dashboardControls: DashboardControl[];
};

export const ProgressReport: FunctionComponent<ProgressReportProps> = ({ dashboardControls }) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.container}>
      <Flex gap='4' data-testid='progress-report'>
        <ProgressReportLegend progressReports={dashboardControls} />
        <Separator orientation='vertical' className={classes.separator} />
        <ProgressReportTabs statuses={dashboardControls} />
      </Flex>
    </Card>
  );
};
