export enum CONTROL_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  NOT_REQUIRED = 'NOT_REQUIRED'
}

export enum CONTROL_STATUS_LABELS {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  NOT_REQUIRED = 'Not required'
}

export type CONTROL_STATUS_KEYS = keyof typeof CONTROL_STATUS;

export const CONTROL_SORT_OPTIONS: { name: string; value: string }[] = [
  { name: 'Control ID', value: 'externalControlId,asc' },
  { name: 'Highest Risk', value: 'securityRisk,asc' },
  { name: 'Lowest Risk', value: 'securityRisk,desc' },
  { name: 'Highest Impact', value: 'businessRisk,asc' },
  { name: 'Lowest Impact', value: 'businessRisk,desc' },
  { name: 'Last Updated', value: 'lastUpdatedAt,desc' }
];
