import { FunctionComponent, useMemo } from 'react';
import { Select } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { CONTROL_SORT_OPTIONS } from 'app/constants/controls';

const useStyles = tss.withName('SortSelect').create(() => ({
  trigger: {
    padding: 'var(--space-2) var(--space-3)',
    backgroundColor: 'var(--white)',
    cursor: 'pointer',
    color: 'var(--font-primary-color)',
    height: '40px',

    '&:hover': {
      backgroundColor: 'var(--accent-a3)'
    }
  }
}));

type SortSelectProps = {
  defaultValue: string;
  onChange: (value: string) => void;
};
export const SortSelect: FunctionComponent<SortSelectProps> = ({ defaultValue, onChange }) => {
  const { classes } = useStyles();

  // if an invalid defaultValue is provided, then use the first option
  const value = useMemo(
    () =>
      CONTROL_SORT_OPTIONS.find(({ value }) => value === defaultValue)
        ? defaultValue
        : CONTROL_SORT_OPTIONS[0].value,
    [defaultValue]
  );

  return (
    <Select.Root size='2' defaultValue={value} onValueChange={onChange}>
      <Select.Trigger
        color='gray'
        variant='soft'
        className={classes.trigger}
        data-testid='sort-select-trigger'
      />
      <Select.Content color='gray' variant='soft' data-testid='sort-select-content'>
        {CONTROL_SORT_OPTIONS.map(option => (
          <Select.Item key={option.value} value={option.value}>
            {option.name}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};
