export enum URLS {
  DASHBOARDS = 'dashboards',
  CONTROLS = 'controls'
}

export const CONTROLS_API_URLS = {
  ROOT: '/customer-controls',
  DETAIL: '/customer-controls/:controlId',
  INITIAL_FINDINGS: '/customer-controls/:controlId/initialFindings'
};

export const SOLUTIONS_API_URLS = {
  ROOT: '/solutions'
};

export const USERS_API_URLS = {
  ROOT: '/users',
  LOGGED_IN_USER: '/users/logged-in-user'
};

export const DASHBOARD_CONTROLS_API_ROOT = {
  ROOT: '/dashboard-controls'
};
