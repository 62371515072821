import { Text } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { RISK_VALUE } from 'app/constants/dashboard';

const useStyles = tss.withName('ProgressReportHelpers').create(() => ({
  riskLabel: {
    fontWeight: 700,
    borderBottom: '2px solid',
    fontSize: '16px',
    lineHeight: '12px',
    fontFamily: 'Source Code Pro Variable'
  },
  riskLabelLow: {
    color: 'var(--green-a6)',
    borderBottomColor: '2px solid var(--green-a6)'
  },
  riskLabelMedium: {
    color: 'var(--amber-a6)',
    borderBottomColor: '2px solid var(--amber-a6)'
  },
  riskLabelHigh: {
    color: 'var(--red-a6)',
    borderBottomColor: '2px solid var(--red-a6)'
  }
}));

export const RiskLabel = (value: RISK_VALUE) => {
  const { classes } = useStyles();
  const riskLabelName = `${value.at(0)!.toUpperCase()}${value.slice(1).toLowerCase()}`;
  return (
    <Text
      size='2'
      className={clsx(
        classes.riskLabel,
        classes[`riskLabel${riskLabelName}` as keyof typeof classes]
      )}
    >
      {value}
    </Text>
  );
};