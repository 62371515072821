import { FunctionComponent, useEffect } from 'react';
import { matchPath, Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import { NotFoundPage } from 'app/components';
import { URLS } from 'app/constants/routes';

import { Controls } from './Controls';
import { Dashboard } from './Dashboard';

const defaultDocumentTitle = 'Overwatch';

const ROUTES = [
  {
    path: URLS.DASHBOARDS,
    element: <Dashboard />,
    title: 'Overwatch - Dashboards'
  },
  {
    path: URLS.CONTROLS,
    element: <Controls />,
    title: 'Overwatch - Controls'
  }
];

const Routes: FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = ROUTES.find(route => matchPath(route.path, location.pathname));

    document.title = currentRoute ? currentRoute.title : defaultDocumentTitle;
  }, [location]);

  return (
    <ReactRoutes>
      <Route path='/'>
        {ROUTES.map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route index element={<Navigate to={URLS.DASHBOARDS} replace={true} />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routes;
