import { FunctionComponent } from 'react';
import { Flex } from '@radix-ui/themes';

import { CustomerControl } from 'app/types';

import { DetailsSection, InitialFindingsSection, StatusSection } from './Sections';

type DetailsTabContentProps = {
  details: CustomerControl;
};

export const DetailsTabContent: FunctionComponent<DetailsTabContentProps> = ({ details }) => {
  return (
    <Flex gap='4' justify={'between'} direction={'column'}>
      <StatusSection controlStatus={details.status} />
      <InitialFindingsSection control={details} />
      <DetailsSection control={details} />
    </Flex>
  );
};
