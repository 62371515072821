import { createContext, PropsWithChildren, useContext } from 'react';

import { concatUserTypeAndRole } from 'app/constants/rbac';
import { ApiError } from 'app/types/ApiError';
import { User } from 'app/types/users';

import { useFetchLoggedInUser } from './useUserQueries';

type LoggedInUserContextType = {
  isLoading: boolean;
  error?: Error | ApiError | null;
  user?: User;
  isAllowed: (allowedRoles: string[]) => boolean;
};

const LoggedInUserContext = createContext<LoggedInUserContextType>({
  isLoading: true
} as LoggedInUserContextType);

export const LoggedInUserProvider = (props: PropsWithChildren) => {
  const { isLoading, data, error } = useFetchLoggedInUser();

  return (
    <LoggedInUserContext.Provider
      value={{
        isLoading,
        user: data,
        error,
        isAllowed: (allowedRoles: string[]) =>
          data ? allowedRoles.includes(concatUserTypeAndRole(data.type, data.role)) : false
      }}
    >
      {props.children}
    </LoggedInUserContext.Provider>
  );
};

export const useLoggedInUser = () => useContext(LoggedInUserContext);
