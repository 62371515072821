import { FunctionComponent } from 'react';
import { Box, Flex, Tabs } from '@radix-ui/themes';
import { isThisMonth } from 'date-fns';
import { tss } from 'tss-react';

import { DashboardStatusIcon } from 'app/components/Dashboard';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { DashboardControl } from 'app/types/dasboard';

import { sortByStatus } from '../dashboard.utils';
import { groupedByRiskMap } from '../helpers/RiskGrouped';

const useStyles = tss.withName('ProgressReportTabs').create(() => ({
  container: {
    flex: 1
  },
  list: {
    backgroundColor: 'var(--bg-default)',
    padding: '5px',
    borderRadius: '6px',
    boxShadow: 'none',
    gap: '5px',
    width: 'fit-content'
  },
  trigger: {
    padding: '6px 12px',
    borderRadius: '6px',
    flex: 1,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',

    '& > span': {
      fontSize: '14px',
      backgroundColor: 'inherit',
      display: 'flex',
      gap: '10px',
      fontWeight: 700,
      padding: '0'
    },

    '&::before': {
      content: 'none'
    },

    '&:focus, &:hover, &[data-state=active]': {
      backgroundColor: 'var(--white)'
    }
  }
}));

type ProgressReportTabsProps = {
  statuses: DashboardControl[];
};

export const ProgressReportTabs: FunctionComponent<ProgressReportTabsProps> = ({ statuses }) => {
  const { classes } = useStyles();

  const statussesCompletedThisMonth = statuses
    .filter(({ status }) => status === DASHBOARD_STATUS.COMPLETED)
    .filter(({ completedAt }) => isThisMonth(completedAt));

  const allStatusesSorted = [
    ...statuses.filter(status => !statussesCompletedThisMonth.includes(status)),
    //change status name from COMPLETED to COMPLETED_THIS_MONTH when completedAt is this month
    ...statussesCompletedThisMonth.map(status => ({
      ...status,
      status: DASHBOARD_STATUS.COMPLETED_THIS_MONTH
    }))
  ].sort(sortByStatus);

  return (
    <Tabs.Root defaultValue='default' className={classes.container}>
      <Tabs.List className={classes.list}>
        <Tabs.Trigger value='default' className={classes.trigger} data-testid='default-view-tab'>
          Default view
        </Tabs.Trigger>
        <Tabs.Trigger value='risk' className={classes.trigger} data-testid='risk-tab'>
          Risk
        </Tabs.Trigger>
        <Tabs.Trigger value='impact' className={classes.trigger} data-testid='impact-tab'>
          Impact
        </Tabs.Trigger>
      </Tabs.List>

      <Box pt='4' width={'100%'}>
        <Tabs.Content value='default'>
          <Flex wrap={'wrap'} align={'center'}>
            {allStatusesSorted.map(status => (
              <div key={status.name}>
                <DashboardStatusIcon status={status.status} />
              </div>
            ))}
          </Flex>
        </Tabs.Content>
        <Tabs.Content value='risk'>
          <Flex wrap={'wrap'} align={'center'}>
            {groupedByRiskMap(allStatusesSorted, 'securityRisk')}
          </Flex>
        </Tabs.Content>
        <Tabs.Content value='impact'>
          <Flex wrap={'wrap'} align={'center'}>
            {groupedByRiskMap(allStatusesSorted, 'businessRisk')}
          </Flex>
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
};
