import { FunctionComponent } from 'react';
import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

// import { useFetchCustomerControl } from 'app/hooks/useControlQueries';
import { DashboardStatusIcon } from 'app/components/Dashboard';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { DashboardControl } from 'app/types/dasboard';

const useStyles = tss.withName('ProgressReportLegend').create(() => ({
  heading: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '1.556'
  },
  percentageContainer: {
    display: 'flex',
    alignContent: 'flex-start',
    fontWeight: 900
  },
  percentageValue: {
    fontSize: '41px',
    lineHeight: '29px',
    letterSpacing: '-4.1px',
    fontWeight: 400
  },
  percentageSymbol: {
    fontSize: '18px',
    lineHeight: '18px',
    marginLeft: '3px'
  },
  total: {
    fontSize: '16px',
    lineHeight: '1.06',
    fontWeight: 900
  },
  statusText: {
    fontSize: '12px',
    lineHeight: '1.667'
  }
}));

type ProgressReportLegendProps = {
  progressReports: DashboardControl[];
};

export const ProgressReportLegend: FunctionComponent<ProgressReportLegendProps> = ({
  progressReports
}) => {
  const { classes } = useStyles();
  const completedStatuses = progressReports.filter(
    progressReport => progressReport.status === DASHBOARD_STATUS.COMPLETED
  );

  const completedStatussesPercentage = Math.round(
    (completedStatuses.length / progressReports.length) * 100
  );

  return (
    <Box flexBasis={'225px'}>
      <Flex direction='column' gap='4'>
        <Heading as='h3' className={classes.heading}>
          Progress Report
        </Heading>

        <Flex gap='4'>
          <Text className={classes.percentageContainer}>
            <span className={classes.percentageValue}>{completedStatussesPercentage}</span>
            <span className={classes.percentageSymbol}>%</span>
          </Text>
          <Text className={classes.total}>
            {completedStatuses.length} of {progressReports.length}
            <br />
            controls completed
          </Text>
        </Flex>

        <Box>
          <Flex gap='2' direction={'column'}>
            {Object.values(DASHBOARD_STATUS).map(status => (
              <Flex key={status} align={'center'} gap='6px'>
                <DashboardStatusIcon status={status} />
                <Text className={classes.statusText}>{status}</Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
