import { compareDesc } from 'date-fns';

import { CONTROL_STATUS } from 'app/constants/controls';
import { CustomerControl } from 'app/types/controls';
import { CustomerControlFilters } from 'app/types/filters';

/**
 * URLSearchParams
 */
export const buildSearchParamsFromFilters = (
  filters: CustomerControlFilters,
  urlParams = new URLSearchParams()
) => {
  urlParams.delete('freeText');
  if (filters.freeText) urlParams.append('freeText', filters.freeText);

  urlParams.delete('solutionId');
  if (filters.solutionId) urlParams.append('solutionId', filters.solutionId);

  urlParams.delete('status');
  if (filters.status) urlParams.append('status', filters.status);

  return urlParams;
};

export const buildFiltersFromSearchParams = (urlParams: URLSearchParams) => {
  const statusFromParams = urlParams.get('status');
  const status = statusFromParams
    ? Object.keys(CONTROL_STATUS).find(val => val === statusFromParams)
      ? statusFromParams
      : ''
    : undefined;

  return {
    freeText: urlParams.get('freeText') || undefined,
    solutionId: urlParams.get('solutionId') || undefined,
    status
  };
};

/**
 * Filtering
 */
const filterBySolutionId = (controls: CustomerControl[], { solutionId }: CustomerControlFilters) =>
  solutionId ? controls.filter(control => `${control.solution?.id}` === solutionId) : controls;

const filterByStatus = (controls: CustomerControl[], { status }: CustomerControlFilters) =>
  status ? controls.filter(control => control.status === status) : controls;

const filterByFreeText = (controls: CustomerControl[], { freeText }: CustomerControlFilters) =>
  freeText
    ? controls.filter(
        control =>
          `${control.externalControlId}`.includes(freeText.toLocaleLowerCase()) ||
          control.name.toLocaleLowerCase().includes(freeText.toLocaleLowerCase()) ||
          control.controlNotes?.toLocaleLowerCase().includes(freeText.toLocaleLowerCase()) ||
          control.initialFindings?.toLocaleLowerCase().includes(freeText.toLocaleLowerCase())
      )
    : controls;

export const filterControls = (controls: CustomerControl[], filters: CustomerControlFilters) => {
  if (controls.length === 0) return controls;
  // filters applied in the order: solutionId, status, freeText
  return filterByFreeText(filterByStatus(filterBySolutionId(controls, filters), filters), filters);
};

/**
 * Sorting
 */
const convertRiskToNumber = (risk?: string) => {
  if (risk) {
    if (risk.toLocaleLowerCase() === 'high') return 0;
    if (risk.toLocaleLowerCase() === 'medium') return 1;
    if (risk.toLocaleLowerCase() === 'low') return 2;
  }
  return 3;
};

export const sortControls = (controls: CustomerControl[], sort: string) => {
  const [sortProperty, sortDir] = sort.split(',');

  if (sortProperty === 'securityRisk' || sortProperty === 'businessRisk') {
    return sortDir === 'asc'
      ? controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) < convertRiskToNumber(b[sortProperty]) ? -1 : 1
        )
      : controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) > convertRiskToNumber(b[sortProperty]) ? -1 : 1
        );
  }

  if (sortProperty === 'lastUpdatedAt') {
    return controls.sort((a, b) =>
      compareDesc(
        a.lastUpdatedAt ? new Date(a.lastUpdatedAt) : new Date(),
        b.lastUpdatedAt ? new Date(b.lastUpdatedAt) : new Date()
      )
    );
  }

  // default is by externalControlId
  return controls.sort((a, b) => (a.externalControlId < b.externalControlId ? -1 : 1));
};
