import { isThisMonth } from 'date-fns';

import { DASHBOARD_STATUS, RISK_VALUE } from 'app/constants/dashboard';
import { DashboardControl, Solution } from 'app/types';

type SolutionControlGroup = {
  solution: Solution;
  controls: DashboardControl[];
};

export const groupedByRisk = (
  statuses: DashboardControl[],
  riskType: 'businessRisk' | 'securityRisk'
): Record<string, DashboardControl[]> => {
  const result: Record<string, DashboardControl[]> = {};

  const sortedStatuses = [...statuses].sort((a, b) => {
    const aRiskLevel = a[riskType];
    const bRiskLevel = b[riskType];
    const riskOrder = Object.values(RISK_VALUE);

    return riskOrder.indexOf(aRiskLevel) - riskOrder.indexOf(bRiskLevel);
  });

  for (const item of sortedStatuses) {
    const riskLevel = item[riskType];

    if (!result[riskLevel]) {
      result[riskLevel] = [];
    }
    result[riskLevel]!.push(item);
  }

  return result;
};

export const sortByStatus = (a: DashboardControl, b: DashboardControl) =>
  Object.values(DASHBOARD_STATUS).indexOf(a.status) -
  Object.values(DASHBOARD_STATUS).indexOf(b.status);

export const groupBySolution = (dashboardControls: DashboardControl[]): SolutionControlGroup[] => {
  const solutionGroups: SolutionControlGroup[] = [];

  const uniqueSolutions = [...new Set(dashboardControls.map(val => val.solution?.id))];
  uniqueSolutions.map(solutionId => {
    solutionGroups.push({
      solution: dashboardControls.find(({ solution }) => solution?.id === solutionId)!.solution,
      controls: dashboardControls.filter(({ solution }) => solution?.id === solutionId)
    });
  });
  return solutionGroups;
};

export const isCompletedThisMonth = (control: DashboardControl) =>
  isThisMonth(control.completedAt) ? DASHBOARD_STATUS.COMPLETED_THIS_MONTH : control.status;
