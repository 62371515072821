import { FunctionComponent, useEffect, useState } from 'react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { IconButton, TextField } from '@radix-ui/themes';
import { tss } from 'tss-react';
import { useDebounce } from 'use-debounce';

const useStyles = tss.withName('FreeTextFilter').create(() => ({
  textfield: {
    boxShadow: 'inset 0 0 0 var(--text-field-border-width) var(--slate-200)'
  },
  textfieldIcon: {
    paddingLeft: '16px',
    paddingRight: '8px'
  }
}));

const MILLIS_FOR_DEBOUNCE = 250;

type FreeTextFilterProps = {
  defaultValue?: string;
  onChange: (value: string) => void;
};
export const FreeTextFilter: FunctionComponent<FreeTextFilterProps> = ({
  defaultValue = '',
  onChange
}) => {
  const { classes } = useStyles();

  const [freeText, setFreeText] = useState(defaultValue);

  const [debouncedValue] = useDebounce(freeText, MILLIS_FOR_DEBOUNCE);

  const handleClearFreeText = () => {
    setFreeText('');
    onChange('');
  };

  useEffect(() => {
    // trim any white space before/after
    onChange(freeText.trim().replace(/\s\s+/g, ' '));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <TextField.Root
      className={classes.textfield}
      data-testid='freetext-filter'
      placeholder='Search...'
      size='3'
      value={freeText}
      onChange={e => setFreeText(e.target.value)}
    >
      <TextField.Slot className={classes.textfieldIcon}>
        <MagnifyingGlass size={16} />
      </TextField.Slot>
      {defaultValue && (
        <TextField.Slot>
          <IconButton
            size='1'
            variant='ghost'
            color='gray'
            onClick={handleClearFreeText}
            data-testid='freetext-filter-reset'
          >
            <X size={16} />
          </IconButton>
        </TextField.Slot>
      )}
    </TextField.Root>
  );
};
