import { FunctionComponent } from 'react';
import { UserCircle } from '@phosphor-icons/react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { IconButton } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { useAuthContext, useIsAuthenticated } from 'app/auth';

const useStyles = tss.withName('UserMenu').create(() => ({
  userList: {
    display: 'flex',
    justifyContent: 'end',
    padding: '8px 40px',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0
  },

  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: 'auto'
  },

  menu: {
    listStyle: 'none',
    width: '250px',
    padding: '24px',
    margin: '0'
  },

  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '12px',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '1',
    '&:focus': {
      boxShadow: '0 0 0 2px var(--slate-7)'
    },
    '&:hover': {
      backgroundColor: 'var(--slate-3)'
    }
  },

  title: {
    fontWeight: '700',
    lineHeight: '1',
    color: 'var(--black-a12)'
  },

  subtitle: {
    color: 'var(--font-secondary-color)',
    lineHeight: '1.4'
  },

  viewportContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    top: '100%',
    left: '0',
    perspective: '2000px'
  },
  viewport: {
    position: 'relative',
    width: '100%',
    maxWidth: 'var(--radix-navigation-menu-viewport-width)',
    height: 'var(--radix-navigation-menu-viewport-height)',
    marginTop: '-8px',
    marginRight: '24px',
    overflow: 'hidden',
    backgroundColor: 'var(--white)',
    border: '1px solid var(--slate-200)',
    borderRadius: '6px',
    boxShadow: '0px 4px 6px 0px #00000017'
  }
}));

export const UserMenu: FunctionComponent = () => {
  const { classes } = useStyles();
  const { instance } = useAuthContext();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = () => {
    instance.logoutRedirect();
  };
  return (
    <>
      {isAuthenticated && (
        <>
          <NavigationMenu.List className={classes.userList} data-testid='user-menu'>
            <NavigationMenu.Item>
              <NavigationMenu.Trigger asChild data-testid='user-menu-trigger'>
                <IconButton variant='ghost' color='gray' highContrast>
                  <UserCircle width='24' height='24' weight='fill' />
                </IconButton>
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className={classes.content}>
                <ul className={classes.menu} data-testid='user-menu-list'>
                  <NavigationMenu.Link asChild>
                    <li
                      className={classes.menuItem}
                      onClick={handleLogout}
                      data-testid='user-menu-logout-item'
                    >
                      <div className={classes.title}>Log Out</div>
                      <div className={classes.subtitle}>Sign out from Overwatch</div>
                    </li>
                  </NavigationMenu.Link>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          </NavigationMenu.List>
          <div className={classes.viewportContainer}>
            <NavigationMenu.Viewport className={classes.viewport} />
          </div>
        </>
      )}
    </>
  );
};
