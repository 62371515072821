import { FunctionComponent, useMemo, useState } from 'react';
import { Infinity } from '@phosphor-icons/react';
import { Button, Flex } from '@radix-ui/themes';
import { clsx } from 'clsx';
import { tss } from 'tss-react';

import { ControlStatusIcon } from 'app/components';
import { CONTROL_STATUS, CONTROL_STATUS_KEYS, CONTROL_STATUS_LABELS } from 'app/constants/controls';

const useStyles = tss.withName('StatusFilter').create(() => ({
  container: {
    margin: 'var(--space-2) var(--space-3) 0',
    gap: '20px'
  },
  button: {
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '1.4',
    fontWeight: '700',
    gap: '10px',
    width: 'fit-content'
  },
  active: {
    backgroundColor: 'var(--white)'
  }
}));

type StatusFilterProps = {
  defaultValue?: string;
  onChange: (value: string) => void;
};
export const StatusFilter: FunctionComponent<StatusFilterProps> = ({
  defaultValue = '',
  onChange
}) => {
  const { classes } = useStyles();

  const [selectedStatus, setSelectedStatus] = useState(defaultValue);

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
    onChange(value);
  };

  const statusKeys = useMemo(() => Object.keys(CONTROL_STATUS) as CONTROL_STATUS_KEYS[], []);

  return (
    <Flex direction='column' className={classes.container}>
      <Button
        color='gray'
        variant='ghost'
        size='3'
        className={clsx(classes.button, selectedStatus === '' && classes.active)}
        onClick={() => handleStatusChange('')}
        data-testid={'status-filter-button-ALL'}
      >
        <Infinity size={16} />
        All
      </Button>

      {statusKeys.map(val => (
        <Button
          key={val}
          color='gray'
          variant='ghost'
          size='3'
          className={clsx(classes.button, selectedStatus === val && classes.active)}
          onClick={() => handleStatusChange(val)}
          data-testid={`status-filter-button-${val}`}
        >
          <ControlStatusIcon size={16} status={CONTROL_STATUS[val]} />
          {CONTROL_STATUS_LABELS[val]}
        </Button>
      ))}
    </Flex>
  );
};
