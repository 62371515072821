import { FunctionComponent, ReactNode } from 'react';
import { Callout } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('EmptyResult').create(() => ({
  root: {
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)'
  },
  title: {
    color: 'var(--font-primary-color)',
    fontSize: '18px',
    lineHeight: 1.5
  }
}));

type EmptyResultProps = {
  title?: string;
  content?: ReactNode;
};

export const EmptyResult: FunctionComponent<EmptyResultProps> = ({ title, content }) => {
  const { classes } = useStyles();

  return (
    <Callout.Root
      color='gray'
      variant='surface'
      size='2'
      className={classes.root}
      data-testid='controls-empty'
    >
      <Callout.Text className={classes.title}>{title || 'No results found'}</Callout.Text>
      {content && <Callout.Text>{content}</Callout.Text>}
    </Callout.Root>
  );
};
