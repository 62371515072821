import { FunctionComponent } from 'react';
import { Warning } from '@phosphor-icons/react';
import { Callout } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('ControlsList').create(() => ({
  error: {
    maxWidth: '640px'
  }
}));

export const ConnectionError: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <Callout.Root
      color='red'
      role='alert'
      variant='surface'
      size='3'
      className={classes.error}
      data-testid='controls-error'
    >
      <Callout.Icon>
        <Warning size={32} weight='fill' />
      </Callout.Icon>
      <Callout.Text>An connection error occurred.</Callout.Text>
      <Callout.Text>Please try refreshing the page.</Callout.Text>
    </Callout.Root>
  );
};
