import { FC, PropsWithChildren } from 'react';
import { tss } from 'tss-react';

const useStyles = tss.withName('PageWrapper').create(() => ({
  container: {
    display: 'flex',
    gap: '16px',
    overflow: 'auto',
    padding: '16px',
    height: 'calc(100% - 32px)', // 32px = top padding + bottom padding
    background: 'var(--bg-default)'
  }
}));

export const PageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.container}>{children}</div>;
};
