import axios from 'axios';

import { getAccessToken } from 'app/auth';

export const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_ENABLE_MOCK_SERVICE_WORKER === 'true'
      ? ''
      : process.env.REACT_APP_BASE_API
        ? process.env.REACT_APP_BASE_API
        : '/api'
});

axiosInstance.interceptors.request.use(async request => {
  try {
    const accessToken = await getAccessToken();

    if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(error?.message || 'Unable to obtain access token');
  }

  return request;
});
