import { FunctionComponent } from 'react';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { Box, Flex } from '@radix-ui/themes';
import { format } from 'date-fns';

import { SolutionIcon } from 'app/components/Controls';
import { CustomerControl } from 'app/types/controls';

import { SidePanelDetailsField } from './SidePanelDetailsField';
import { SidePanelSection } from './SidePanelSection';

type DetailsSectionProps = {
  control: CustomerControl;
};

export const DetailsSection: FunctionComponent<DetailsSectionProps> = ({ control }) => {
  return (
    <SidePanelSection label='Details'>
      <Box>
        <Flex gap='2' direction={'column'}>
          <SidePanelDetailsField
            label='Licenced'
            type='icon'
            value={
              control.licenced ? (
                <CheckCircle
                  size={24}
                  weight='fill'
                  color='var(--rag-light-green)'
                  data-testid='licenced'
                />
              ) : (
                <XCircle
                  size={24}
                  weight='fill'
                  color='var(--rag-red)'
                  opacity={0.3}
                  data-testid='not-licenced'
                />
              )
            }
          />
          <SidePanelDetailsField
            label='Solution'
            type='text'
            value={
              control.solution && (
                <Flex as='span' align='center' gap='8px'>
                  {control.solution?.name}
                  <SolutionIcon solution={control.solution} />
                </Flex>
              )
            }
          />
          <SidePanelDetailsField label='Control version' type='text' value={control.version} />
          <SidePanelDetailsField
            label='Last updated'
            type='text'
            value={format(control.lastUpdatedAt || new Date(), 'd MMM yyyy')}
          />
        </Flex>
      </Box>
    </SidePanelSection>
  );
};
