export enum USER_ROLES {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  READER = 'READER'
}

export enum USER_TYPES {
  CUSTOMER = 'CUSTOMER',
  THREATSCAPE = 'THREATSCAPE'
}
