/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthenticationResult, Configuration, PublicClientApplication } from '@azure/msal-browser';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: process.env.REACT_APP_AUTHORITY || '',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || '/',
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI || '/'
  },

  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
};

const MOCK_INSTANCE = {
  initialize: () => Promise.resolve(),

  acquireTokenSilent: () =>
    Promise.resolve({
      accessToken: '123'
    } as AuthenticationResult),

  getAllAccounts: () => [],
  getActiveAccount: () => {},

  enableAccountStorageEvents: () => {},
  logoutRedirect: () => Promise.resolve(),

  addEventCallback: (_e: any) => {},
  setActiveAccount: (_e: any) => {}
} as unknown as PublicClientApplication;

export const AUTH_INSTANCE =
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_AUTH === 'true'
    ? MOCK_INSTANCE
    : new PublicClientApplication(MSAL_CONFIG);
