import { FunctionComponent, ReactNode } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('CustomerControlCard').create(() => ({
  container: {
    fontSize: '12px',
    lineHeight: 1.667
  },
  label: {
    color: 'var(--font-secondary-color)'
  },
  value: {
    color: 'var(--font-primary-color)',
    textAlign: 'right'
  }
}));

type DetailsFieldProps = {
  label: string;
  type: 'text' | 'icon';
  value?: ReactNode;
};

export const SidePanelDetailsField: FunctionComponent<DetailsFieldProps> = ({
  label,
  type,
  value
}) => {
  const { classes } = useStyles();

  return (
    <Flex gap='2' justify={'between'} className={classes.container}>
      <Text as='p' className={classes.label}>
        {label}
      </Text>
      {type === 'icon' && value}
      {type === 'text' && (
        <Text as='p' className={classes.value}>
          {value || '-'}
        </Text>
      )}
    </Flex>
  );
};
