import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/400-italic.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import '@fontsource-variable/source-code-pro';
import '@radix-ui/themes/styles.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

import App from 'app/App';
import { AUTH_INSTANCE, AuthProvider } from 'app/auth';

import reportWebVitals from './reportWebVitals';

async function enableMocking() {
  if (process.env.REACT_APP_ENABLE_MOCK_SERVICE_WORKER === 'true') {
    const { worker } = await import('./mock-api/browser');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
      onUnhandledRequest(req, print) {
        if (
          req.url.includes('static/media') ||
          req.url.includes('hot-update') ||
          req.url.includes('/logo192') ||
          req.url.includes('login.microsoftonline.com')
        )
          return;
        print.warning();
      }
    });
  }
  return;
}

function initApp() {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <AuthProvider instance={AUTH_INSTANCE}>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
}

AUTH_INSTANCE.initialize().then(() => {
  if (process.env.NODE_ENV !== 'test') {
    const accounts = AUTH_INSTANCE.getAllAccounts();
    // Default to using the first account if no account is active on page load
    if (!AUTH_INSTANCE.getActiveAccount() && accounts.length > 0) {
      AUTH_INSTANCE.setActiveAccount(accounts[0]);
    }

    AUTH_INSTANCE.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const { account } = event.payload as AuthenticationResult;
        AUTH_INSTANCE.setActiveAccount(account);
      }
    });

    // This will update account state if a user signs in from another tab or window
    AUTH_INSTANCE.enableAccountStorageEvents();
  }

  enableMocking().then(initApp);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
