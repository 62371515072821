import {
  IMsalContext,
  useIsAuthenticated as useMSALIsAuthenticated,
  useMsal
} from '@azure/msal-react';

import { AUTH_INSTANCE } from './constants';

export const useAuthContext =
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_AUTH === 'true'
    ? () => ({ instance: AUTH_INSTANCE }) as unknown as IMsalContext
    : useMsal;

export const useIsAuthenticated =
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_AUTH === 'true'
    ? () => true
    : useMSALIsAuthenticated;
