import { FunctionComponent } from 'react';
import { Badge } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { Solution } from 'app/types';

import { SolutionIcon } from '../SolutionIcon';

const useStyles = tss.withName('SolutionTag').create(() => ({
  container: {
    color: 'var(--slate-500)',
    padding: 'var(--space-1)',
    boxShadow: 'inset 0 0 0 1px var(--slate-200)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '12px',

    '&>svg': {
      height: '16px',
      width: '16px'
    }
  }
}));

type SolutionTagProps = {
  solution: Solution;
};

export const SolutionTag: FunctionComponent<SolutionTagProps> = ({ solution }) => {
  const { classes } = useStyles();

  return (
    <Badge size='3' color='gray' variant='outline' className={classes.container}>
      <SolutionIcon solution={solution} />
      {solution.name}
    </Badge>
  );
};
