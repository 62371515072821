import { FunctionComponent } from 'react';
import { Flex } from '@radix-ui/themes';

import { ConnectionError, PageLoadingIndicator, PageWrapper } from 'app/components';
import { useFetchDashboardControls } from 'app/hooks/useDashboardControlsQueries';

import { ProgressReport } from './ProgressReport/ProgressReport';
import { Solutions } from './Solutions/Solutions';

export const Dashboard: FunctionComponent = () => {
  const { data, isFetching, error } = useFetchDashboardControls(true);
  return (
    <PageWrapper>
      {isFetching && <PageLoadingIndicator />}
      {error && (
        <div data-testid='dashboard-error'>
          <ConnectionError />
        </div>
      )}
      {data && (
        <Flex gap={'4'} direction={'column'} height={'fit-content'}>
          <ProgressReport dashboardControls={data} />
          <Solutions dashboardControls={data} />
        </Flex>
      )}
    </PageWrapper>
  );
};
