import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { SOLUTIONS_API_URLS } from 'app/constants/routes';
import { Solution } from 'app/types';

export const useFetchSolutions = (enabled = false) =>
  useQuery({
    queryKey: ['useFetchSolutions'],
    queryFn: () =>
      axiosInstance.get<Solution[]>(SOLUTIONS_API_URLS.ROOT, {}).then(response => response.data),
    enabled
  });
