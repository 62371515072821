import { FunctionComponent } from 'react';
import { ChatText, ListDashes } from '@phosphor-icons/react';
import { Box, Tabs, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { CustomerControl } from 'app/types/controls';

import { DetailsTabContent } from './DetailsTab/DetailsTabContent';

const useStyles = tss.withName('SidePanelTabs').create(() => ({
  list: {
    backgroundColor: 'var(--bg-default)',
    padding: '5px',
    borderRadius: '6px',
    boxShadow: 'none',
    gap: '5px'
  },
  trigger: {
    padding: '6px 12px',
    borderRadius: '6px',
    flex: 1,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',

    '& > span': {
      fontSize: '14px',
      backgroundColor: 'inherit',
      display: 'flex',
      gap: '10px',
      fontWeight: 700,
      padding: '0'
    },

    '&::before': {
      content: 'none'
    },

    '&:focus, &:hover, &[data-state=active]': {
      backgroundColor: 'var(--white)'
    }
  }
}));

type SidePanelTabsProps = {
  details: CustomerControl;
};

export const SidePanelTabs: FunctionComponent<SidePanelTabsProps> = ({ details }) => {
  const { classes } = useStyles();

  return (
    <Tabs.Root defaultValue='details'>
      <Tabs.List className={classes.list}>
        <Tabs.Trigger value='details' className={classes.trigger} data-testid='details-tab'>
          <ListDashes width='16' height='16' weight='bold' />
          Details
        </Tabs.Trigger>
        <Tabs.Trigger value='notes' className={classes.trigger} data-testid='notes-tab'>
          <ChatText width='16' height='16' weight='fill' />
          Notes
        </Tabs.Trigger>
      </Tabs.List>

      <Box pt='2'>
        <Tabs.Content value='details'>
          <DetailsTabContent details={details} />
        </Tabs.Content>

        <Tabs.Content value='notes'>
          <Text size='2'>Notes...</Text>
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
};
