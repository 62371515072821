import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { axiosInstance } from 'app/constants/axios';
import { USERS_API_URLS } from 'app/constants/routes';
import { ApiError } from 'app/types/ApiError';
import { User } from 'app/types/users';

export const useFetchLoggedInUser = () => {
  return useQuery({
    queryKey: ['loggedInUser'],
    queryFn: () =>
      axiosInstance
        .get<User>(USERS_API_URLS.LOGGED_IN_USER)
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        })
  });
};
